<template>
  <div :ref="identifier"
       class="dj-view-relative"
       :style="{
                width:size,
                height:height+ 'px',
                borderRadius:borderRadius,
                backgroundColor:bgColor
              }"
       @click="click">
    <slot :height="height"/>
  </div>
</template>

<script >

import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";

export default {
  name: "dj-view-relative",
  props:{
    size:{type:String,default:'100px'},
    ratio:{type:[String],default:'56.25%'},
    borderRadius:{type:[String],default:'0'},
    bgColor:{type:[String],default:'transparent'},
  },
  data(){
    return {
      identifier:dj_identifier(),
      height:'auto'
    }
  },
  mounted() {
this.setHeight();
  },
  methods:{
    click(){
      this.$emit('click')
    },
    setHeight(){
      const self = this;
      this.$nextTick(function () {
        let info = this.$refs[self.identifier];
        self.height = info.offsetWidth * self.ratio.dj_percentageToNumber() ;
      }, 100);
    }
  },
  watch:{
    ratio(value){
      this.setHeight();

    }
  }
}
</script>

<style scoped>
.dj-view-relative{
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: transparent;
}
</style>
