<template>
  <div class="dj-square"
        :style="{
                  'width':size,
                  'paddingBottom':ratio,
                  'borderRadius':borderRadius
                }">
    <div class="dj-square__container">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: "dj-square",
  props:{
    size:{type:String,default:'100px'},
    ratio:{type:String,default:'100%'},
    borderRadius:{type:String,default:'0px'},
  },
  data(){
    return {
    }
  },


}
</script>

<style scoped lang="scss">
.dj-square{
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  &__container{
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
}
</style>
