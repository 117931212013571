import Vue from 'vue'
import Router from 'vue-router'
import UiLoginCtrl from '@/router/libs/@base'

/** 路由守卫 **/
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)



const defaultRouteName = [
]
/*创建路由方法*/
const createRouter = () => new Router({
  mode: 'history',
  routes: UiLoginCtrl,
  linkActiveClass:'active'
})
const router = createRouter()

/*重置路由*/
export function resetRouter(routes = [])
{
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
  routes.forEach(item=>{router.addRoute(item)})
}




export function generaMenu(routes, data,whiteList) {
  data.forEach(item => {
    const menu = {
      path: item.path,
      redirect: item.redirect.length === 0?'':
        item.redirect.dj_containSubString('/')?
          to=>{
            return item.redirect
          }:
          {name:item.redirect},
      children: [],
      params:item.params,
      component: () => import(`@/${item.component}.vue`),
      meta: {
        title:item['meta_title'],
        icon:item['meta_icon'],
        svg:item['meta_svg'],
        children: item.children,
        path:item.path,
        default_path: item.default_path,

      },
      hidden: item.hidden,

      name: item.name,
      props:Object.keys(item.params).length>0
    }
    if (item.children !== undefined) {
      generaMenu(menu.children, item.children,whiteList)
    }
    whiteList.push(item.name)
    routes.push(menu)
  })
}


export default router

