import dj_postRequest from "@/@common/@apis/@@base";

export function api_system_file_upload_image(parameter={}){
    return dj_postRequest('cms/system/file/upload/image',parameter);
}
export function api_system_file_upload_video(parameter={}){
    return dj_postRequest('cms/system/file/upload/video',parameter);
}
export function api_system_file_upload_wps(parameter={}){
    return dj_postRequest('cms/system/file/upload/wps',parameter);
}
