Number.prototype.dj_pad = function(n = 2) {
    let s = "";
    for (let i = 1; i < n; i++) {
        s += '0';
    }
    return (s + this).slice(-1 * n);
}
Number.dj_randomInt = function(min = 1,max = 9999) {
    switch(arguments.length){
        case 1:
            return parseInt(Math.random() * min + 1,10);
            break;
        case 2:
            return parseInt(Math.random() * (max - min + 1 ) + min,10);
            break;
        default:
            return 0;
            break;
    }
}
Number.dj_pad = function(number,n = 2) {
    let s = "";
    for (let i = 1; i < n; i++) {
        s += '0';
    }
    return (s + number).slice(-1 * n);
}

Number.dj_percentageToPoint = function (percentage = '80%') {
    let str= percentage.replace("%","");
    return Number(str/100);
}

Number.dj_randomPercentage = function (min = 50,max = 100) {
    return Number.dj_randomInt(min,max) + '%';
}