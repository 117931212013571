<template>
  <dj-form-cell :width="width"
                :label-width="labelWidth"
                :rules="rules"
                :label="label"
                :prop="prop"
                :disabled="disabled"
                :required="required"
                :suffix="suffix"
                :suffix-name="suffixName"
                :tips="tips"
                @dj_suffixHandle="dj_suffixHandle">
    <el-input class="dj-form-input"
              v-model="currentValue"
              :disabled="Boolean(disabled)"
              :placeholder="placeholder"
              :maxlength="Number(maxlength)"
              :minlength="Number(minlength)"
              :show-word-limit="showWordLimit"
              @input="handleInput"
              @focus="handleFocus"
              @blur="handleBlur"
              @change="handleChange"/>
    <template v-slot:suffix>
      <slot name="suffix"/>
    </template>
  </dj-form-cell>
</template>

<script>
import NsFormInput from "@/@@@dj-framework/kit/dj-form/input/libs/NsFormInput";
import DjFormCell from "@/@@@dj-framework/kit/dj-form/dj-form-cell";

export default {
  name: "dj-form-input",
  components: {DjFormCell},
  mixins:[NsFormInput],
  methods:{
    dj_suffixHandle(){
      this.$emit('dj_suffixHandle')
    },
    handleBlur(event) {
      this.$emit('blur', this.currentValue,event);
    },
    handleFocus(event) {
      this.$emit('focus', this.currentValue,event);
    },
    handleInput(event) {
      this.$emit('input', this.currentValue,event);
    },
    handleChange(event) {
      this.$emit('change', this.currentValue,event);
    },
  }
}
</script>

<style scoped lang="scss">
.dj-form-input{
  width: 100%;
  border-radius: 0 !important;
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;

}
::v-deep .el-input__inner{
  border-radius: 0 !important;
  height: 36px !important;
  line-height: 36px !important;
}



</style>
