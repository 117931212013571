export default {
    props:{
        cancelText:{type:String,default:'取消'},
        cancelDisabled:{type:Boolean,default:false},
        cancelType:{type:String,default:'info'},
        confirmText:{type:String,default:'保存'},
        confirmDisabled:{type:Boolean,default:false},

    },
    methods:{
        dj_cancelHandle(){
            this.$emit('dj_cancelHandle');
        },
        dj_confirmHandle(){
            this.$emit('dj_confirmHandle');
        }
    }
}
