<template>
  <div class="dj-view-absolute"
       :style="{width:width,height:height}" @click="click">
    <slot/>
  </div>
</template>

<script >
export default {
  name: "dj-view-absolute",
  props:{
    width:{type:String,default:'100%'},
    height:{type:String,default:'100%'},
bgColor:{type:String,default:'transparent'},
  },
  methods:{
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.dj-view-absolute{
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
}
</style>