let COS = require('cos-js-sdk-v5');
class DjTencentCosManager {
    constructor () {
        this.cosSecretId = 'AKIDkJceQwYuzoMcbKmwpSotgCSR9akeHdiW';
        this.cosSecretSecretKey = 'YppeIgndCVWHlyuZx4uSklUw83A1buvf';
        this.cosBucket = 'dhjkb-1307670323';
        this.cosRegion = 'ap-beijing';
        this.cosStorageClass = 'STANDARD';
    }
    dj_uploadSingleFileToCos(file,compressor = true,base64=false){
        return new Promise((resolve, reject) => {
            this.dj_compressorImage(file,false).then(result=>{
                let options = { SecretId: this.cosSecretId, SecretKey: this.cosSecretSecretKey };
                let cos = new COS(options);
                cos.putObject({
                    Bucket: this.cosBucket,
                    Region: this.cosRegion,
                    Key: 'news/mine/'+result.name,
                    StorageClass: this.cosStorageClass,
                    Body: result,
                }, function(err, data) {
                    if (err === null){
                        let fileData = {
                            type:file.type.dj_toArray('/')[0],
                            name:file.name,
                            path:'https://'+data['Location'],
                            cover:'https://'+data['Location']
                        }
                        if (base64){
                            fileData['base64'] = file['base64']
                        }
                        resolve(fileData);
                    }else {
                        reject();
                    }
                });
            })
        })

    }
    /**
     * @name 文件压缩
     * @description
     *  1、将文件转img对象
     *  2、获取文件宽高比例
     *  3、自定义等比例缩放宽高属性，这里我用的是固定800宽度，高度是等比例缩放
     *  4、canvas重新绘制图片
     *  5、canvas转二进制对象转文件对象，返回
     * @returns { File } 文件
     */
    dj_compressorImage(file,compressor = true){
        return new Promise((resolve, reject) => {
            if (compressor === false){
                resolve(file)
            }else {
                this.fileToImg(file).then(img=>{
                    const canvas = document.createElement('canvas')
                    const context = canvas.getContext('2d')
                    const { width: originWidth, height: originHeight } = img
                    const scale = +(originWidth / originHeight).toFixed(2)
                    const targetWidth = 500 ;
                    const targetHeight = Math.round(500 / scale) ;
                    canvas.width = targetWidth
                    canvas.height = targetHeight
                    context.clearRect(0, 0, targetWidth, targetHeight)
                    context.drawImage(img, 0, 0, targetWidth, targetHeight)
                    const type = 'image/png'
                    canvas.toBlob(function (blob) {
                        const f = new File([blob], file.name, {
                            type,
                            lastModified: file.lastModified
                        })
                        resolve(f)
                    }, type)
                });

            }


        })

    }

    static dj_uploadFileToServer(){

    }



    // file转换成img对象
     fileToImg (file) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            const reader = new FileReader()
            reader.onload = function (e) {
                img.src = e.target.result
            }
            reader.onerror = function (e) {
                reject(e)
            }
            reader.readAsDataURL(file)
            img.onload = function () {
                resolve(img)
            }
            img.onerror = function (e) {
                reject(e)
            }
        })
    }



}
export default DjTencentCosManager;
