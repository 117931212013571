<template>
  <el-popconfirm :disabled="disabled"
                 width="200px"
                 icon="InfoFilled"
                 confirm-button-type="danger"
                 confirm-button-text='继续删除'
                 cancel-button-text='再想想'
                 cancel-button-type="info"
                 icon-color="red"
                 title="一旦删除，该数据将无法恢复"
                 @confirm="confirm">
    <template #reference>
      <slot/>
    </template>
  </el-popconfirm>
</template>

<script>
export default {
  name: 'dj-button-delete',
  props:{
    disabled:{type:Boolean,delete:false}
  },
  methods:{
    confirm(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
