<template>
  <div>

    <dj-drawer v-model="currentValue" @drawerCloseCallback="drawerCloseCallback">
      <dj-media-upload-header slot="header" @uploadHandle="uploadHandle">
      </dj-media-upload-header>
      <div class="dj-media-image-drawer-collection">
        <dj-collection :loading="loading"
                       :paginate="paginate"
                       :data-source="dataSource"
                       :batch-delete="false"
                       :total="paginate.total"
                       border
                       @dj_pageChangeHandle="dj_pageChangeHandle">
          <template v-slot:cell="{object,index}">
            <div class="dj-media-image-drawer-cell">
              <dj-media-image :media="object.path" media-size="100%"/>
              <div class="dj-user-not-select dj-line-1-1 dj-media-image-drawer-cell__name ">
                {{object.name}}
              </div>
              <el-button class="dj-media-image-drawer-cell__button" type="primary" size="mini"
                         @click="dj_didObjectAtIndex(object,index)">选用</el-button>
            </div>
          </template>
        </dj-collection>
      </div>
    </dj-drawer>

    <dj-media-image-upload-drawer v-if="present"
                                  v-model="present"
                                  :media-ratio="mediaRatio"
                                  @dj_uploadSuccess="dj_pageChangeHandle"/>
  </div>

</template>

<script>
import DjDrawer from "@/@@@dj-framework/kit/dj-drawer/dj-drawer";
import NsDrawer from "@/@@@dj-framework/kit/dj-drawer/libs/NsDrawer";
import DjMediaImageUploadDrawer from "@/@@@dj-framework/kit/dj-media/dj-media-image/dj-media-image-upload-drawer";
import DjMediaUploadHeader from "@/@@@dj-framework/kit/dj-media/components/dj-media-upload-header";
import {api_mine_image_list} from "@/@common/@apis/mine/image";
import DjCollection from "@/@@@dj-framework/kit/dj-collection/dj-collection";
import DjMediaImage from "@/@@@dj-framework/kit/dj-media/dj-media-image/dj-media-image";
export default {
  name: 'dj-media-image-drawer',
  mixins:[NsDrawer],
  components: {DjMediaImage, DjCollection, DjMediaUploadHeader, DjMediaImageUploadDrawer, DjDrawer},
props:{
  mediaRatio:{type:String,default:'80%'}
},
  data(){
    return {
      present:false,
      requestResult:undefined,
      loading:false,
      paginate:{page: 1, pageSize: 32, total: 0 },
      dataSource:[]
    }
  },
  mounted() {
    // this.dj_requestHandle();
  },
  methods:{
    uploadHandle(){
      this.present = true
    },
    dj_didObjectAtIndex(model,index){
      this.$emit('dj_didObjectAtIndex',model,index);
    },
    dj_requestHandle(){
      this.loading = true;
      api_mine_image_list(this.paginate).then(result=>{
        this.dataSource = result['data'];
        this.paginate.page = result['current_page'];
        this.paginate.total = result['total'];
        this.loading = false;
      }).catch(error=>{
        this.loading = false;

      })
    },
    dj_pageChangeHandle(page){
      this.paginate.page = page;
      this.dj_requestHandle();
    },

  },
  watch:{
    value(val){
      this.currentValue = val;
      if (val === true){
        this.dj_requestHandle();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dj-media-image-drawer-collection{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 15px;
}
.dj-media-image-drawer-cell{
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  &__name{
    margin-top: 8px;
    font-size: 13px;
    line-height: 15px;
    height: 15px;
  }
  &__button{
    width: 100%;
    height: 24px;
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 2px;
    margin-top: 8px !important;
  }
}
::v-deep .dj-media-image-drawer-cell__button .el-button{
  width: 100%;
  height: 24px;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  margin-top: 8px !important;
}
</style>
