<template>
  <el-container class="base-collection-container"
  :class="{'base-collection-container__border':border}">
    <el-main>
      <div class="base-collection"
           :class="{'base-collection__border':border}"
           :style="{
                  '--columns':columns,
                  '--collectionSpace':space
                 }">
        <div v-for="(object,index) in dataSource"
             :key="index"
             class="base-collection__cell">
          <slot name="cell" :object="object" :index="index">
            {{index}}
          </slot>
        </div>
      </div>
    </el-main>
  </el-container>

</template>

<script>
import NsCollection from "@/@@@dj-framework/kit/dj-collection/libs/NsCollection";
import DjContainerScroll from "@/@@@dj-framework/kit/@@dj-screen/dj-container/dj-container-scroll";

export default {
  name: "base-collection",
  components: {DjContainerScroll},
  mixins:[NsCollection],
  props:{
    dataSource:{type:[Array],default:function () {
        return [];
      }},

  }
}
</script>

<style scoped lang="scss">
.base-collection-container{
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__border{
    border:1px solid #dcdfe6;
  }
}
.base-collection{
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: var(--collectionSpace);
  grid-template-columns:repeat(var(--columns),1fr);
  box-sizing: border-box;
  overflow-y: auto;
  background-color: white;
  &__border{
    background-color: #dcdfe6;
    grid-gap:1px !important;
    //background-color: white !important;
  }
  &__cell{
    width: 100%;
    height: auto;
    background-color: white;
    box-sizing: border-box;
    overflow: hidden;
  }

}
</style>