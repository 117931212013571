<template>
<div class="dj-media-upload-header">
<el-button type="success" size="mini" @click="uploadHandle">
  <dj-media-label media="upload" media-size="15px" :label="uploadName"
                  @click="uploadHandle"/>
</el-button>
</div>
</template>

<script>
import DjMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon";
import DjMediaLabel from "@/@@@dj-framework/kit/dj-media/dj-media-label/dj-media-label";
export default {
name: "dj-media-upload-header",
  components: {DjMediaLabel, DjMediaIcon},
  props:{
  uploadName:{type:String,default:'本地上传'}
  },
  methods:{
    uploadHandle(){
      this.$emit('uploadHandle')
    }
  }
}
</script>

<style scoped lang="scss">
.dj-media-upload-header{

}
 .el-button{
  margin: 0;
  border-radius: 2px !important;
  height: 26px !important;
  line-height: 26px !important;
  padding: 0 12px !important;
  font-size: 13px !important;
  font-weight: normal !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>