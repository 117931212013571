import dj_postRequest from "@/@common/@apis/@@base";

export function ns_user_login(parameter){
  return dj_postRequest('cms/login',parameter);
}
export function ns_user_verify(parameter){
  return dj_postRequest('cms/verify',parameter);
}
export function ns_user_routes(parameter){
  return dj_postRequest('cms/info',parameter);
}


