import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
//全局进度条的配置
NProgress.configure({
  speed: 1000,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.1
})

import router, { resetRouter } from './router'
import store from './store'
import { dj_getToken } from '@/store/cookies'
function loginOut(next,to){
  store.dispatch('ui_loginOut').then(result=>{
    resetRouter();
    if (to.path === '/login' ||
        to.path.dj_containSubString('paper/format/')) {
      next();
    } else {
      next({ path: '/login' })
    }
    NProgress.done();
  });
}
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = '大河健康报';
  if (to.path.dj_containSubString('paper/format/')){
    next();
    NProgress.done();
  }else {
    const token = dj_getToken();
    if (Object.dj_isEmpty(token)){
      loginOut(next,to)
    }else {
      const routes = store.getters.routes;
      if (routes === undefined){
        store.dispatch('ui_refreshBrowserHandle',routes).then(result=>{
          resetRouter(result);
          next({...to, replace: true });
          NProgress.done();
        }).catch(error=>{
          loginOut(next,to);
        });
      }else {
        next();
        NProgress.done();
      }
    }
  }

})

/*注册一个全局守卫*/
router.beforeResolve((to, from, next) => {
  next();
})

/*全局后置钩子*/
router.afterEach(() => {
  // finish progress bar
  //每次请求结束后都需要关闭进度条
  NProgress.done()

})
