export default {
  props:{
    media:{type:String,default:''},
    mediaSize:{type:String,default:'200px'},
    mediaRatio:{type:String,default:'100%'},
    borderRadius:{type:String,default:'0px'},
    mode:{type:String,default:'contain'},
    video:{type:Boolean,default:false},
    videoUrl:{type:String,default:''},

    preview:{type:Boolean,default:true},

    select:{type:Boolean,default:false},
    iconDelete:{type:Boolean,default:false},
    active:{type:Boolean,default:false},
    inActive:{type:Boolean,default:false},



    customClick:{type:Boolean,default:false},
  }
}
