import {generaMenu} from "@/router";
import { dj_getToken, dj_removeToken, dj_setToken } from '@/store/cookies'
import { dj_sendErrorMessage, dj_sendLoading } from '@/@@@dj-framework/libs/function/elementUi'
import { ns_user_login, ns_user_routes, ns_user_verify } from '@/@common/@apis/mine/login'



const user = {
  state : {
    userInfo:undefined,
    routes:undefined,
    whiteList:[],
    userToken:dj_getToken() ,
    domainInfo:undefined

  },
  mutations:{
    setDomainInfo(state, data = {}){
      state.domainInfo = data;
    },
    removeDomainInfo(state){
      state.domainInfo = undefined ;
    },
    setUserInfo(state, data = {}){
      state.userInfo = data;
    },
    removeUserInfo(state){
      state.userInfo = undefined ;
    },
    setToken(state, data = {}){
      state.userToken = data['token'] ;
      dj_setToken(data['token'])
    },
    removeToken(state){
      state.userToken = undefined;
      dj_removeToken();
    },
    setRoute(state, routes = []){
      state.routes = routes[0]['children']
    },
    removeRoute(state){
      state.routes = undefined
    },
    setWhiteList(state, pages = []){
      state.whiteList = pages
    },

  },
  actions:{
    ui_loginHandle({ commit }, data){
      let loading = dj_sendLoading('登录中……');
      return new Promise((resolve, reject) => {
        ns_user_login(data).then(result=>{
          console.log(data)
          commit('setToken', result);
          loading.close();
          resolve();
        }).catch(error=>{
          dj_sendErrorMessage(error,loading);
          reject();
        })
      })
    },
    ui_verifyHandle({ commit }){
      return new Promise((resolve, reject) => {
        ns_user_verify().then(result=>{
          resolve();
        }).catch(error=>{
          reject();
        })
      })
    },
    ui_updateUserInfoHandle({ commit }, data){
      commit('setUserInfo', data);
    },
    ui_refreshBrowserHandle({ commit },data){
      return new Promise((resolve, reject) => {
        ns_user_routes(data).then(result=>{
          let localData = result['routes'];
          commit('setRoute', localData);
          commit('setUserInfo', result['userInfo']);
          commit('setToken', result['userInfo']);
          let routes = [];
          let whiteList = [];
          generaMenu(routes,localData,whiteList);
          commit('setWhiteList', whiteList);
          resolve(routes);
        }).catch(error=>{
          reject();
        })
      })
    },
    ui_loginOut({ commit }){
      return new Promise((resolve, reject) => {
        commit('removeToken');
        commit('removeUserInfo');
        commit('removeRoute');
        commit('setWhiteList');
        commit('removeDomainInfo');
        resolve()
      })
    },

  }
}
export default user
