<template>
  <dj-view-relative class="dj-user-select"
                    :size="mediaSize"
                    :ratio="mediaRatio"
                    :border-radius="borderRadius"
                    :bg-color="video?'#333333':'transparent'"
                    >
    <template v-slot:default="{height}">
      <dj-view-absolute>
        <el-image class="dj-media-image" :src="media"
                  :fit="mode"
                  :preview-src-list="preview&&!video?[media]:[]"/>
      </dj-view-absolute>
      <dj-view-absolute v-if="video" @click="playHandle">
        <div class="dj-media-image-video">
          <dj-media-icon media="media-play" :media-size="height*0.25 + 'px'"/>
        </div>
      </dj-view-absolute>
      <dj-view-absolute v-if="select || iconDelete || inActive || active"
                        class="dj-media-image__icon"
                        width="auto" height="auto"
                        @click="click">
        <dj-media-icon v-if="iconDelete"
                       media="form-delete"
                       media-color="red"
                       :media-size="height*0.12 + 'px'"/>
        <dj-media-icon v-if="inActive" media="form-select-square-0"
                       media-color="white"
                       :media-size="height*0.12 + 'px'"/>
        <dj-media-icon v-if="active"
                       media="form-select-square-2"
                       media-color="#19be6b"
                       :media-size="height*0.12 + 'px'"/>
      </dj-view-absolute>
    </template>
  </dj-view-relative>
</template>

<script >

import DjViewRelative from "@/@@@dj-framework/kit/@dj-view/position/dj-view-relative";
import DjViewAbsolute from "@/@@@dj-framework/kit/@dj-view/position/dj-view-absolute";
import DjMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon";
import NsMediaImage from "@/@@@dj-framework/kit/dj-media/dj-media-image/libs/NsMediaImage";
export default {
  name: "dj-media-image",
  mixins:[NsMediaImage],
  props:{
    useClick:{type:Boolean,default:true}
  },
  components: {DjMediaIcon, DjViewAbsolute, DjViewRelative},
  methods:{
    clickHandle(){

    },
    playHandle(){
      window.open(this.videoUrl,'_blank');
    },
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.dj-media-image{
  width: 100%;
  height: 100%;
  &__video{
    background-color: black;
  }
  &__icon{
    width: auto;
    height: auto;
    background-color: rgba(3,3,3,0.35);
    padding: 4px 8px;
    color: white;
    right: 0;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.dj-media-image-video{
  width: 100%;
  height: 100%;
  background-color: rgba(3,3,3,0.35);
  color: white;
  font-weight: 600;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>