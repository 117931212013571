
export default {
    props:{
        value:{type:Boolean,default:false},
        clearMask:{type:Boolean,default:false},
        drawerWidth:{type:[String,Number], default:'600px'},
        drawerTitle:{type:String, default:'请填写标题'},
        destroyOnClose:{type:Boolean, default:false},
        appendToBody:{type:Boolean, default:true},
        drawerLoading:{type:Boolean,default:false},
        wrapperClosable:{type:Boolean,default:true},
    },
    data(){
        return {
            currentValue:this.value
        }
    },
    methods:{
        drawerCloseCallback(){
            this.$emit('input',this.currentValue)
            this.$emit('drawerCloseCallback');
        }
    },
    watch:{
        value(val){
            this.currentValue = val;
        }
    }
}
