<template>
  <div class="app">
    <router-view/>
<!--    <resize-observer @notify="handleResize" />-->

  </div>
</template>
<script>
import NsComponents from '@/@@@dj-framework/libs/mixins/NsComponents'

export default {
  mixins:[NsComponents],
  mounted () {
    const self = this;
    // this.dj_resize(function () {
    //   console.log('变好了')
    //   let elements = document.getElementsByClassName('dj-charts')
    //   Array.from(elements).forEach(element=>{
    //     let echarts = element['__vue__']['charts']
    //     echarts.resize()
    //   })
    // })

  },


}
</script>
<style lang="scss">
@import "@/@@@dj-framework/style/custom/dj-theme.scss";

.app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: white;
  font-family: "Songti SC";
  box-sizing: border-box;
  overflow: hidden;
}

#nprogress .bar {
  background: $dj-type-primary !important; //自定义颜色
  height: 2px !important;
}

</style>
<script>
import UiLoginCtrl from "@/pages/@@login/ctrl/UiLoginCtrl";
import DjMediaImageDrawer from '@/@@@dj-framework/kit/dj-media/dj-media-image/dj-media-image-drawer'
export default {
  components: { DjMediaImageDrawer, UiLoginCtrl}
}
</script>
