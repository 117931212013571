<template>
  <div class="dj-border-bottom dj-drawer-header">
    <div class="dj-user-select dj-border-right dj-drawer-header__left" @click="closeHandle">
      <dj-media-icon media="arrow-left"/>
      <div>关闭</div>
    </div>
    <div class="dj-drawer-header__right">
      <slot/>
    </div>
  </div>
</template>

<script>
import DjMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon";
export default {
  name: "dj-drawer-header",
  components: {DjMediaIcon},
  methods:{
    closeHandle(){
      this.$emit('drawerCloseCallback');
    }
  }
}
</script>

<style scoped lang="scss">
.dj-drawer-header{
  padding: 12px 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1 !important;
  &__left{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 12px;
    width: auto;
    font-size: 13px;
    color: #303133;
    font-weight: 500;
  }
  &__right{
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;


  }
}
</style>