import {Loading, Message} from "element-ui";

export function dj_sendLoading(title='加载中……'){
    return Loading.service({ lock: true, text: title,  spinner: 'el-icon-loading',  background: 'rgba(0, 0, 0, 0.35)' });
}
export function dj_hiddenLoading(loading){
    loading.close();
}
export function dj_sendSuccessMessage(message = "",loading = undefined,duration=3000){
    if (loading !== undefined){loading.close();}
    Message({showClose: false, message: message, type: 'success',duration:duration});
}
export function dj_sendErrorMessage(message = "",loading = undefined){
    if (loading !== undefined){loading.close();}
    Message({showClose: false, message: message, type: 'error'});
}