export default {
  props:{
    position:{type:String,default:'left'},
    space:{type:String,default:'6px'},

    number:{type:Boolean,default:false},
    label:{type:[String,Number,undefined],default:''},
    labelSize:{type:String,default:'inherit'},
    labelWeight:{type:String,default:'inherit'},
    labelColor:{type:String,default:'inherit'},


    type:{type:String,default:'icon'},
    media:{type:String,default:'clock'},
    mediaSize:{type:String,default:'inherit'},
    mediaWeight:{type:String,default:'inherit'},
    mediaColor:{type:String,default:'inherit'},
  }
}
