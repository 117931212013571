<template>
  <dj-container v-loading="loading">
    <base-collection :data-source="dataSource"
                     :columns="columns"
                     :space="space"
                     :border="border"
                     >
      <template v-slot:cell="{object,index}">
        <slot name="cell" :object="object" :index="index"/>
      </template>
    </base-collection>
    <div slot="footer" v-if="pagination">
      <dj-pagination :selection="false"
                     :batch-delete="batchDelete"
                     :paginate="paginate"
                     :page-size="paginate.pageSize"
                     :total="total"
                     @dj_pageChangeHandle="dj_pageChangeHandle"/>
    </div>
  </dj-container>

</template>

<script>
import DjContainer from "@/@@@dj-framework/kit/@@dj-screen/dj-container/dj-container";
import BaseCollection from "@/@@@dj-framework/kit/dj-collection/components/base-collection";
import NsCollection from "@/@@@dj-framework/kit/dj-collection/libs/NsCollection";
import DjContainerScroll from "@/@@@dj-framework/kit/@@dj-screen/dj-container/dj-container-scroll";
import DjPagination from "@/@@@dj-framework/kit/dj-pagination/dj-pagination";
export default {
  name: "dj-collection",
  mixins:[NsCollection],
  components: {DjPagination, DjContainerScroll, BaseCollection, DjContainer},

methods:{
  dj_pageChangeHandle(page){
    this.$emit('dj_pageChangeHandle',page);
  },
},
  watch:{

  }
}
</script>

<style scoped>

</style>