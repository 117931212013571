<template>
  <div class="ui-login-ctrl">
    <div class="ui-login-ctrl__container">
      <div class="ui-login-ctrl__container__login">
        <div class="ui-login-ctrl__container__login__header">
          大河健康报
        </div>
        <dj-form size="medium" :model="model">
          <el-form-item label-width="0">
            <el-input  v-model="model.phone" placeholder="请输入手机号码">
              <dj-media-icon class="dj-margin-3-left" slot="prefix" media-size="15px" media="phone"/>
            </el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-input v-model="model.password" type="password"
                      placeholder="请输入密码">
              <dj-media-icon class="dj-margin-3-left" slot="prefix" media-size="15px" media="password"/>
            </el-input>
          </el-form-item>
        </dj-form>
        <el-button type="primary" class="ui-login-ctrl__container__button" @click="loginHandle">
          立即登录
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DjForm from '@/@@@dj-framework/kit/dj-form/dj-form'
import DjFormInput from '@/@@@dj-framework/kit/dj-form/input/dj-form-input'
import DjMediaIcon from '@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon'
import dj_md5 from '@/@@@dj-framework/libs/function/md5'
import { dj_sendLoading, dj_sendSuccessMessage } from '@/@@@dj-framework/libs/function/elementUi'
export default {
  name: "UiLoginCtrl",
  components: { DjMediaIcon, DjFormInput, DjForm },
  data(){
    return {
      model:{
        phone:'',
        password:''
      }
    }
  },
  methods:{
    test(){
      const self = this;
      this.$store.dispatch('ui_loginHandle',{phone:'18837155168',password:'1a55b6b0ff77967719a33d15bdde51f4'}).then((routes) => {
        self.$router.push('/')

      }).catch(error=>{

      })
    },
    loginHandle(){
      const self = this;
       let loading = dj_sendLoading('登录中')
      // this.ctrlModel.formModel.md5 = dj_md5.hex_md5(value+'dhjkb03'+'dhjkb2006')

      const md5 = dj_md5.hex_md5(this.model.phone+this.model.password+'dhjkb2006')
      this.$store.dispatch('ui_loginHandle',{phone:this.model.phone,password:md5}).then((routes) => {
        dj_sendSuccessMessage('登录成功',loading);
        self.$router.push('/')

      }).catch(error=>{

      })
    }
  }
}
</script>

<style scoped lang="scss">
.ui-login-ctrl{
  width: 100vw;
  height: 100vh;
  /* 加载背景图 */
  background-image: url("../../../../public/img/bg-image.png");
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  /* 设置背景颜色，背景图加载过程中会显示背景色 */
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container{
    margin-top: -150px;
    margin-left: 0px;
    &__login{
      padding: 30px;
      background-color: white;
      border-radius: 6px;
      width: 260px;
      &__header{
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bolder;
        color: #008a9c;
        text-align: center;
      }
    }
    &__button{
      width: 260px;

    }

  }
}
.login-input{
  height: 44px !important;
  line-height: 44px !important;
  background-color: white;
  font-size: 24px !important;
  font-weight: 600;
  color: #303133;
}
::v-deep .login-input .el-input__inner{
  border-radius: 0 !important;
  height: 44px !important;
  line-height: 44px !important;
  background-color: white;
  font-size: 24px !important;
  font-weight: 600;
  color: #303133;
}
</style>
