
Date.dj_todayDate = function(formatStr = 'yyyy-MM-dd HH:mm:ss',offsetMinutes = 0) {
    let timestamp = new Date().getTime();
    timestamp = timestamp + offsetMinutes*60*1000;
    return Date.dj_formatDate(timestamp,formatStr);
};

Date.dj_getNowDate = function(formatStr = 'yyyy-MM-dd HH:mm:ss',$date = '') {
    if (Date.dj_isDate($date)){
        $date = $date.dj_trim('all');
        let date = new Date($date);
        let timestamp = date.getTime();
        return Date.dj_formatDate(new Date(Number(timestamp)),formatStr);
    }
    let timestamp = Date.dj_getNowTimestamp();
    return Date.dj_formatDate(new Date(Number(timestamp)),formatStr);
}
Date.dj_getNowYear = function() {
    let timestamp = new Date().getTime();
    return Number(Date.dj_formatDate(timestamp,'yyyy'));
}
Date.dj_getNowTimestamp  = function(offset = 0,isMs = false) {
    if (isMs){
        let timestamp = Date.parse(new Date())/1000;
        timestamp = timestamp + offset;
        return timestamp;
    }else {
        let timestamp = Date.parse(new Date());
        timestamp = timestamp + offset*1000;
        return timestamp;
    }
}


Date.dj_formatDate = function (date, format="yyyy-MM-dd HH:mm:ss") {
    if (!date) return;
    switch(typeof date) {
        case "string":
            date = new Date(date.replace(/-/g, "/"));
            break;
        case "number":
            date = new Date(date);
            break;
    }
    if (!date instanceof Date) return;
    var dict = {
        "yyyy": date.getFullYear(),
        "M": date.getMonth() + 1,
        "d": date.getDate(),
        "H": date.getHours(),
        "m": date.getMinutes(),
        "s": date.getSeconds(),
        "MM": ("" + (date.getMonth() + 101)).substr(1),
        "dd": ("" + (date.getDate() + 100)).substr(1),
        "HH": ("" + (date.getHours() + 100)).substr(1),
        "mm": ("" + (date.getMinutes() + 100)).substr(1),
        "ss": ("" + (date.getSeconds() + 100)).substr(1)
    };
    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function() {return dict[arguments[0]];});
}

Date.dj_isDate = function(string = '') {

    if(isNaN(string)&&!isNaN(Date.parse(string))){return true;}
    return false;
}

Date.dj_randomDate = function (starTime, endTime) {
    let m = new Date(starTime);
    let mt = m.getTime();
    let n = new Date(endTime);
    let nt = n.getTime();
    let s = nt - mt;
    let sm = Math.floor(Math.random() * s);
    return Date.dj_formatDate(new Date(mt + sm));
}
Date.dj_getDateWeek = function(date = '') {
    let formatDate = Date.dj_formatDate(date,'yyyy-MM-dd')
    let weekArray = ["日", "一", "二", "三", "四", "五", "六"];
    return  weekArray[new Date(formatDate).getDay()];
}
Date.dj_getDays = function(year) {
    let dates = [];
    for (let i = 1; i <= 12; i++) {
        let monthInfo = {month:year+'-'+Number.dj_pad(i,2),days :[]};
        for (let j = 1; j <= new Date(year, i, 0).getDate(); j++) {
            monthInfo.days.push(year+'-'+Number.dj_pad(i,2)+'-'+Number.dj_pad(j,2));
        }
        dates.push(monthInfo)
    }
    return dates;
}
Date.dj_getYears = function(begin,end= Date.dj_getNowYear()){
    let mapper = [];
    for (let index = Number(begin); index<=end;index++){
        mapper.push({
            id:index,
            name:index+'年'
        });
    }
    return mapper.reverse();
}
Date.dj_getYearWeekDay = function(year,weeks = []){
    let dates = [];
    for (let i = 1; i <= 12; i++) {
        let month = Number.dj_pad(i,2);
        let monthInfo = {month:year+'年'+month+'月',days :[]};
        for (let j = 1; j <= new Date(year, i, 0).getDate(); j++) {
            let day = Number.dj_pad(j,2);
            day =year+'-'+month+'-'+day;
            let week = Date.dj_getDateWeek(day);
            if (weeks.dj_containObject(week)){
                if (Date.dj_validDate(day)){
                    monthInfo.days.push({
                        'date':day,
                        'week':'周'+week
                    });
                }
            }
        }
        if (monthInfo.days.length>0){
            dates.push(monthInfo)
        }
    }
    let result = dates.reverse();
    return result;
}
Date.dj_validDate = function(date) {
    let d = new Date(date.replace(/-/g, '/'))
    let todayDate = new Date()
    if (d.setHours(0, 0, 0, 0) > todayDate.setHours(0, 0, 0, 0)) {
        return false
    } else {
        return true
    }
}

Date.dj_formatSeconds = function (seconds = 0){
    let h = parseInt(seconds / 60 / 60 % 24)
    h = h < 10 ? '0' + h : h
    let m = parseInt(seconds / 60 % 60)
    m = m < 10 ? '0' + m : m
    let s = parseInt(seconds % 60)
    s = s < 10 ? '0' + s : s
    return h+' : '+m+' : ' + s;
}
