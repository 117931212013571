import DjFileManger, {dj_selectLocalFile} from "@/@@@dj-framework/libs/class/file/DjFileManger";
import DjTencentCosManager from "@/@@@dj-framework/libs/class/file/DjTencentCosManager";
import {api_mine_image_create} from "@/@common/@apis/mine/image";
import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";
import {api_system_file_upload_image} from "@/@common/@apis/file/upload";

class DjFileImageManager extends DjFileManger{
    constructor() {
        super();
        this.accept = "image/*";
        this.multiple = false;
    }
    dj_selectSingleImage(){
        this.multiple = false;
        return new Promise((resolve, reject) => {
            dj_selectLocalFile(this.accept,this.multiple).then(result=>{
                resolve(result)
            }).catch(error=>{
                reject(error);
            })
        })
    }
    dj_selectMultipleImage(){
        this.multiple = true;
        return new Promise((resolve, reject) => {
            dj_selectLocalFile(this.accept,this.multiple,true).then(result=>{
                resolve(result)
            }).catch(error=>{
                reject(error);
            })
        })
    }


    static dj_uploadBase64DataToCos(imageData){
        let imageFile = dj_data64toFile(imageData);
        return DjFileImageManager.dj_uploadFileObjectToServe(imageFile,0,false);
    }

    static dj_uploadFileObjectToServe(fileObject){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            if (fileObject.hasOwnProperty('name')){
                formData.append('file',fileObject,dj_identifier(8)+'.png')
            }else {
                formData.append("file", fileObject);
            }
            api_system_file_upload_image(formData).then(result=>{
                resolve(result)
            }).catch(error=>{
                reject(error)
            })
        })
    }
static dj_selectMultipleImage(){
        let manager = new DjFileImageManager();
    return new Promise((resolve, reject) => {
        manager.dj_selectMultipleImage().then(result=>{
            resolve(result)
        })
    })

}


}
export default DjFileImageManager;
export function dj_fileBlobToBase64(blobUrl){
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open("get", blobUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function(e) {
            const reader = new FileReader();
            reader.onload = function () {
                const base64 = this.result;
                resolve(base64);
            };
            reader.readAsDataURL(e.target['response']);
        };
        xhr.send();
    })
}

export function dj_data64toFile(base64Data,file_name = dj_identifier(16)) {
    let arr = base64Data.split(","),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);
    while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
    }
    let newBlob = new Blob([u8Arr], {
        type: fileType
    });
    newBlob.lastModifiedDate = new Date();
    newBlob.name = file_name+'.'+fileType.dj_fileTypeSuffix();
    return newBlob;
}


