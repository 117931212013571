<template>
  <div class="dj-media-icon-wrap" @click="click">
    <div class="dj-user-not-select dj-icon-  dj-media-icon"
         :class="'dj-icon-'+media"
         :style="{
             '--mediaSize':mediaSize,
             '--mediaColor':mediaColor,
             '--mediaWeight':mediaWeight,
             lineHeight:mediaLineHeight
      }"/>
  </div>
</template>

<script>

import NsMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/libs/NsMediaIcon";

export default {
  name: "dj-media-icon",
  mixins:[NsMediaIcon],
  methods:{
    click(){
      this.$emit('click')
    }
  }

}
</script>

<style scoped lang="scss">
@import "libs/iconfont.css";
.dj-media-icon-wrap{
  display: inline-flex;
  box-sizing: border-box;
  overflow: hidden;
  background-color: transparent;
}
.dj-media-icon{
  display: inline-flex;
  font-family: "PingFang SC";

  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  font-size: var(--mediaSize) !important;
  font-weight: var(--mediaWeight) !important;
  color: var(--mediaColor) !important;
}
</style>
