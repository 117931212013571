import axios from 'axios'
import store from "@/store";
import router from "@/router";
import { dj_getToken } from '@/store/cookies'


const DjRequest = axios.create({
    timeout: 1000 * 108180,
    withCredentials: true,
})

/**
 * 请求拦截
 */
DjRequest.interceptors.request.use(config => {
    let token = dj_getToken();
    // config.headers['Access-Control-Allow-Origin'] = '*';
    if (token !== undefined){config.headers['token'] = token;}
    return config
}, error => {
    return Promise.reject(error)
})

/**
 * 响应拦截
 */
DjRequest.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code !== 200) {
            if (res.code === 400){
                store.dispatch('ui_loginOut').then(result=>{
                    router.push('/login')
                });
            }else {
                return Promise.reject(res.msg);
            }
        }else {
            return Promise.resolve(response.data.data);
        }
    },
    error => {
        return Promise.reject(error);
    }
)
export default DjRequest


export function postService(url,data) {
    return DjRequest({
        url     :url,
        method  :'post',
        data    :data
    })
}
export function postServiceCustom(url,data) {
    return DjRequest({
        url     :url,
        method  :'post',
        data    :data
    })
}






export const dj_get_local_json = function (method) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: method,
            // dataType: "json",
            crossDomain: true,
            cache: false
        }).then(res => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}


export const dj_batchRequest = function (requestFunction,data,callBack){
    let requestCount = data.length;
    if (requestCount === 0){callBack();}
    let requests = [];

    let models = data[0];
    for (let index = 0;models.length;index++){
        models.forEach(item=>{
            requests.push(requestFunction(item));
        })
    }
};
