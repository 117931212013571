import Cookies from 'js-cookie'
const DJ_TOKEN_NAME = 'dhjkb-token3';
export function dj_getToken() {
  let token = Cookies.get(DJ_TOKEN_NAME);
  if (token === undefined || token === 'undefined'){return  undefined;}
  return token
}

export function dj_setToken(token) {
  return Cookies.set(DJ_TOKEN_NAME, token)
}

export function dj_removeToken() {
  return Cookies.remove(DJ_TOKEN_NAME)
}
export function dj_getContent(name) {
  return Cookies.get(name)
}

export function dj_setContent(name,content) {
  return Cookies.set(name, content)
}

export function dj_removeContent(name) {
  return Cookies.remove(name)
}



