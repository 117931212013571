<template>
  <svg :ref="identifier"
       :id="identifier"
       class="dj-media-svg"
       :style="{
                  '--svgSize':mediaSize,
               }"
       aria-hidden="true">
    <use :xlink:href="`#dj-svg-${media}`" @click="click"/>
  </svg>
</template>

<script >
import svg from "./libs/svg";
import NsMediaSvg from "@/@@@dj-framework/kit/dj-media/dj-media-svg/libs/NsMediaSvg";
import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";
export default {
  name: "dj-media-svg",
  mixins:[NsMediaSvg],
  data(){
    return {
      identifier:dj_identifier()
    }
  },
  mounted () {
  },
  methods:{
    click(){
      this.$emit('click');
    },
  }
}
</script>

<style scoped lang="scss">
.dj-media-svg{
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  vertical-align: bottom;
  line-height: 0;
  width: var(--svgSize);
  height: var(--svgSize);
}
</style>
