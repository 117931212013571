export function dj_sleep(value = 30) {
    return new Promise((resolve) => {
        setTimeout(() => {resolve();}, value);
    })
}

export function dj_copyObject(object){
    // 判断是否是对象
    if (typeof object !== 'object') return;
    // 判断obj类型，根据类型新建一个对象或者数组
    let newObj = object instanceof Array ? [] : {}
    // 遍历对象，进行赋值
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            let val = object[key];
            // 判断属性值的类型，如果是对象，递归调用deepCopy
            newObj[key] = typeof val === 'object' ? dj_copyObject(val) : val
        }
    }
    return newObj

}