<template>
  <el-container class="dj-container-scroll">
    <el-header height="auto" class="dj-container-scroll__header">
      <slot name="header"/>
    </el-header>
    <el-main class="dj-container-scroll__main">
      <slot/>
    </el-main>
    <el-footer height="auto" class="dj-container-scroll__footer">
      <slot name="footer"/>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "dj-container-scroll"
}
</script>

<style scoped lang="scss">
.dj-container-scroll{
width: 100%;
  height: 100%;
  background-color: rgb(248,248,248);
  &__header,&__main,&__footer{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  &__header,&__footer {
    overflow: hidden;
  }
}



</style>