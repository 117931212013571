const UiLoginCtrl = [
  {
    path: '/login',
    name: 'UiLoginCtrl',
    meta: {meta_title: '登录'},
    component: () => import('@/pages/@@login/ctrl/UiLoginCtrl.vue')
  },
  {
    path: '/paper/format/:id',
    name: 'UiPaperFormatCtrl',
    meta: {meta_title: '登录'},
    component: () => import('@/pages/paper/paper/format/ctrl/UiPaperFormatCtrl.vue')
  },
  {
    path: '*',
    component: () => import('@/pages/@@404/ctrl/Ui404Ctrl.vue')
  },
]
export default UiLoginCtrl
