<template>
  <div v-if="!Object.dj_isEmpty(label)"
       class="dj-media-label"
       :class="['dj-media-label__'+position]"
       :style="{'--space':space}" @click.stop="click">
    <div class="dj-media-label__media">
      <slot>
        <dj-media-icon v-if="type==='icon'"
                       :media="media"
                       :media-size="mediaSize"
                       :media-color="mediaColor"
                       :media-weight="mediaWeight"/>
        <dj-media-svg v-else-if="type==='svg'"
                      :media="media"
                      :media-size="mediaSize"/>

      </slot>
    </div>
    <div
         class="dj-media-label__label"
         :class="{'dj-media-label__label-number':number}"
         :style="{
             '--labelSize':labelSize,
             '--labelColor':labelColor,
             '--labelWeight':labelWeight
      }">{{label}}</div>
  </div>
</template>

<script>

import NsMediaLabel from "@/@@@dj-framework/kit/dj-media/dj-media-label/libs/NsMediaLabel";
import DjMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon";
import DjMediaSvg from '@/@@@dj-framework/kit/dj-media/dj-media-svg/dj-media-svg'

export default {
  name: "dj-media-label",
  components: { DjMediaSvg, DjMediaIcon},
  mixins:[NsMediaLabel],
  methods:{
    click(){
      this.$emit('click')
    }
  }

}
</script>

<style scoped lang="scss">
.dj-media-label{
  display: inline-flex;
  grid-gap: var(--space);
  &__left{
    align-items: center;
    line-height: 1;
  }
  &__right{
    flex-direction: row-reverse;
    align-items: center;
  }
  &__top{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__bottom{
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  &__label{
    font-family: "PingFang SC";
    font-size: var(--labelSize) !important;
    font-weight: var(--labelWeight) !important;
    color: var(--labelColor) !important;

  }
  &__label-number{
    font-family: "Times New Roman";
  }
}

</style>
