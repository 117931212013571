const system = {
    state : {
        systemInfo: {
            menuWidth:'160px',
            collapse:false,
            presentImageDrawer:false,
        },
    },
    mutations:{
        setSystemInfoCollapse(state){
            if (state.systemInfo.collapse){
                state.systemInfo.menuWidth = '160px';
            }else {
                state.systemInfo.menuWidth = '55px';
            }
            state.systemInfo.collapse = !state.systemInfo.collapse;
        },
        setImageDrawer(state){
            state.systemInfo.presentImageDrawer = !state.systemInfo.presentImageDrawer;
        },
    },
    actions:{
        dj_changeSystemCollapse({ commit }){
            commit('setSystemInfoCollapse');
        },
        dj_changeImageDrawer({ commit }){
            commit('setImageDrawer');
        },

    }
}
export default system
