export default {

    props:{
        width:{type: String, default: '100%' },
        rules: [Object, Array],

        prop: String,

        label:{type: String, default: '' },

        labelWidth: { type: [String,Number], default: '' },

        disabled: { type: [Boolean,Number], default: false },
        required: { type: Boolean, default: false },

        tips:{type: String, default: '' },

        suffix:{ type: Boolean, default: false },
        suffixName:{ type: String, default: '' },
containerWidth:{type: String, default: '100%' },
    }
}
