import dj_postRequest from "@/@common/@apis/@@base";

export function api_mine_image_list(parameter={}){
    return dj_postRequest('cms/system/file/image/list',parameter);
}
export function api_mine_image_create(parameter={}){
    return dj_postRequest('cms/system/file/image/create',parameter);
}
export function api_mine_image_delete(parameter={}){
    return dj_postRequest('cms/system/file/image/delete',parameter);
}
