<template>
  <div class="dj-form-footer">
    <el-button class="dj-form-footer__button"
               type="primary"
               :disabled="confirmDisabled"
               @click="dj_confirmHandle">
      {{confirmText}}
    </el-button>
    <el-button v-if="!Object.dj_isEmpty(cancelText)"
               class="dj-form-footer__button"
               :type="cancelType"
               @click="dj_cancelHandle">
      {{cancelText}}
    </el-button>
  </div>

</template>

<script>
import NsFormFooter from "@/@@@dj-framework/kit/dj-form/libs/NsFormFooter";

export default {
  name: "dj-form-footer",
  mixins:[NsFormFooter]
}
</script>

<style scoped lang="scss">
.dj-form-footer{
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  &__button{
    flex: 1;
    height: 100%;
    letter-spacing: 1px;
  }
}
::v-deep .el-button{
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
</style>
