<template>
  <div class="dj-form"
       :style="{
               '--width':width
             }">
    <el-form :ref="formIdentifier"
             class="dj-form__wrap"
             style="width: 100%"
             :rules="rules"
             :size="size"
             :label-position="labelPosition"
             :model="model"
             :label-width="labelWidth"
             @validate="validateFormCell">
      <slot/>
    </el-form>
  </div>
</template>

<script>
import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";

export default {
  name: "dj-form",
  provide () {
    return {
      form:this,
      formLabelWidth: this.labelWidth,
    }
  },
  props:{
    width:{ type: String, default: '100%' },
    size:{ type: String, default: 'small' },
    labelPosition:{ type: [String], default: 'left' },
    labelWidth:{ type: [String,Number], default: '150px' },
    model:{type:[Object], default:function () {return {};  }},
    rules:{ type: [Object,Array], default: function (){return {}} },

  },
  data(){
    return {
      formIdentifier:dj_identifier()
    }
  },
  methods:{
    validateFormCell(prop,value){
      let children = this.$refs[this.formIdentifier]['$children'];
      children.forEach(child=>{
        if (child.hasOwnProperty('validateState')){
          if (child.prop === prop){
            let result = {value:value,prop:prop};
            child.validateStateChangeHandle(result)
          }
        }
      })
    },
    validateField(value){
      const self = this;
      return new Promise(function (resolve,reject){
        self.$refs[self.formIdentifier].validateField(value,function (error) {
          if (Object.dj_isEmpty(error) === true) {resolve();}
          else {reject();}
        });
      })

    },
    dj_resetFields() {
      this.$refs[this.formIdentifier].resetFields();
    },
    dj_clearValidate() {
      this.$refs[this.formIdentifier].clearValidate();
    },
    validate(){
      let formElement = this.$refs[this.formIdentifier];
      return new Promise(function (resolve,reject) {

        formElement.validate((valid,errorElement) => {
          if (valid === true){
            resolve(valid);
          }else {
            Object.keys(errorElement).forEach((key,i) => {
              if(i===0){
                setTimeout(() => {
                  const errorDiv = document.getElementsByClassName('is-error');
                  errorDiv[0].scrollIntoView(
                      {
                            block: 'center',
                            behavior: 'smooth',
                          }
                  )
                }, 0);
              }
            })
            reject(valid);
          }
        });
      });

    }
  }
}
</script>

<style scoped>

</style>
