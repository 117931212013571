import NsFormCell from "@/@@@dj-framework/kit/dj-form/libs/NsFormCell";

export default {
    mixins:[NsFormCell],
    props:{
        value:{type:[String,undefined]},
        showWordLimit:{type:[Boolean],default:true},
        placeholder:{type:String,default:'请输入您要输入的内容'},
        minlength:{type:[Number,String]},
        maxlength:{type:[Number,String]},
        disabled:{type:[Boolean,Number]},
    },
    data(){
        return {
            currentValue:this.value
        }
    },
    watch:{
        value(val){
            this.currentValue = val;
        }
    }
}
