<template>
  <el-drawer  :visible.sync="currentValue"
             direction="rtl"
             :with-header="false"
             :destroy-on-close="destroyOnClose"
             :append-to-body="appendToBody"
             :size="drawerWidth"
              :wrapper-closable="wrapperClosable"
              @close="closeHandle"
              @opened="openedHandle"
  @open="openHandle">
    <dj-container v-loading="drawerLoading">
      <dj-drawer-header slot="header" @drawerCloseCallback="headerCloseHandle">
        <slot name="header"/>
      </dj-drawer-header>
      <slot/>
      <template v-slot:footer>
        <slot name="footer"/>
      </template>
    </dj-container>
  </el-drawer>

</template>

<script>
import NsDrawer from "@/@@@dj-framework/kit/dj-drawer/libs/NsDrawer";
import DjContainer from "@/@@@dj-framework/kit/@@dj-screen/dj-container/dj-container";
import DjDrawerHeader from "@/@@@dj-framework/kit/dj-drawer/components/dj-drawer-header";

export default {
  name: "dj-drawer",
  components: {DjDrawerHeader, DjContainer},
  mixins:[NsDrawer],
  methods:{
    closeHandle(){
      this.drawerCloseCallback()
    },
    openHandle(){
      this.$emit('openHandle')
    },
    openedHandle(){
      this.$emit('openedHandle')

    },
    headerCloseHandle(){
      this.currentValue = false;
      this.drawerCloseCallback()
    }
  }
}
</script>

<style scoped>

</style>
