const getters = {
    systemInfo: state => state.system.systemInfo,
    // //  用户密码
    token: state => state.user.userToken,
    // routes: state => state.user.userInfo.route,
    routes: state => state.user.routes,
    whiteList: state => state.user.whiteList,
    userInfo: state => state.user.userInfo,

}
export default getters
