import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './permission'


/** 1、清除样式框架 **/
import 'normalize.css/normalize.css'


/** 2、饿了吗组件框架 **/
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import '@/@@@dj-framework/style/element/index.css'
Vue.use(ElementUI, { locale })

import vueBus from '@/@@@dj-framework/libs/class/notification/nofitication'
Vue.use(vueBus);

/** 3、清除样式框架 **/
import '@/@@@dj-framework/@index'
// import { ResizeObserver } from 'vue-resize'
// Vue.component('resize-observer', ResizeObserver)
import domObserve from 'vue-dom-observe';
Vue.use(domObserve);


Vue.config.productionTip = false
window.onresize = () => {return (() => {
  let elements = document.getElementsByClassName('dj-charts')
  Array.from(elements).forEach(element=>{
    let echarts = element['__vue__']['charts']
    echarts.resize()
  })
})();}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
