<template>
  <div >
    <div v-if="tips.length>0" class="dj-form-cell-tips"
    :style="{marginLeft:getLabelWidth}">
      {{tips}}
    </div>
    <el-form-item :ref="identifier"
                  class="dj-form-cell-container "
                  :style="{'--cellHeight':cellHeight}"
                  :label="label"
                  :label-width="getLabelWidth"
                  align="left"
                  :error="error"
                  :prop="prop">
      <div class="dj-form-cell">
        <div :id="containerIdentifier"
             class="dj-form-cell__container"
             :class="{
                        'dj-form-cell__container-flex':containerWidth=='100%'
                     }"
             :style="{
                        'width':containerWidth,
                     }">
          <slot/>
        </div>
        <div v-if="!Object.dj_isEmpty(suffixName) || suffix"
             class="dj-form-cell-container__suffix dj-user-select"
             :style="{'--cellHeight':cellHeight}">
          <slot name="suffix">
            <el-button class="dj-form-cell-container__suffix__button"
                       type="primary" size="small"
                       style="height: 100%;"
                       @click="suffixHandle">
              {{suffixName}}
            </el-button>
          </slot>

        </div>
      </div>
    </el-form-item>

  </div>

</template>

<script>

import NsFormCell from "@/@@@dj-framework/kit/dj-form/libs/NsFormCell";
import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";
import DjMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon";
export default {
  name: "dj-form-cell",
  components: {DjMediaIcon},
  mixins:[NsFormCell],
  inject: ['formLabelWidth','form'],
  data(){
    return {
      identifier:dj_identifier(),
      containerIdentifier:dj_identifier(),
      cellHeight:'auto',
      form:this.form,
      error:''
    }
  },

  mounted() {
    const self = this;
    const myObserver = new ResizeObserver(entries=>{
      entries.forEach(entry => {
        let rect = entry.contentRect;
        self.cellHeight = (rect.height) + 'px';
      });
    })
    myObserver.observe(document.getElementById(this.containerIdentifier));
  },

  computed:{
    getLabelWidth(){
      if (this.labelWidth.length === 0){
        return this.formLabelWidth;
      }else {
        return this.labelWidth;
      }
    },

  },
  methods:{
    resetField(){
    },
    validateState(status){
    },
    validateStateChangeHandle(object){
    },
    suffixHandle(){
      this.$emit('dj_suffixHandle')
    }
  },


}
</script>

<style scoped lang="scss">
@import "@/@@@dj-framework/style/custom/dj-theme.scss";

.dj-form-cell-tips{
  width: 100%;
  box-sizing: border-box;
  //overflow: hidden;
  font-size: 12px;
  margin-left: 12px;
  margin-bottom: 4px;
  color: $dj-type-warning;
  line-height: 1;
}
.dj-form-cell-container{
  //border: 1px #dcdfe6 solid;
  box-sizing: border-box;
  //overflow: hidden;
  align-items: flex-start !important;
  &__label{
    height: 100%;
  }
  &__suffix{
    height: var(--cellHeight);
    box-sizing: border-box;
    overflow: hidden;
    background-color: $dj-type-primary !important;
    //border:1px solid #dcdfe6;
    //border-left: none !important;

    &__button{
      height: 100%;
    }
  }
}
.dj-form-cell{
  display: flex;
  align-items: stretch;
  width: 100%;
  &__container{
    min-height:36px !important;
    height: auto !important;
    box-sizing: border-box;
    overflow: hidden;
  }
  &__container-flex{
    flex: 1;
  }
}

.el-button{
  border: none;
  border-radius: 0;
  height: 0px;
  margin: 0;
  padding: 0 12px;
}

::v-deep .el-form-item__label{
  background-color:#f5f7fa !important;
  border:1px solid #dcdfe6 ;
  border-right: none !important;
  height: var(--cellHeight) !important;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 13px;
  padding: 0 6px !important;
  -moz-user-select:none !important;
  -webkit-user-select:none !important;
  -ms-user-select:none !important;
  -khtml-user-select:none !important;
  user-select:none !important;
}
</style>
