export default {
    props:{
        media:{type:String,default:'clock'},
        mediaSize:{type:String,default:'inherit'},
        mediaWeight:{type:String,default:'inherit'},
        mediaColor:{type:String,default:'inherit'},
        mediaLineHeight:{type:String,default:'1.1'},

    }
}
