<template>
<el-container class="dj-container">
  <el-header height="auto" class="dj-container__header">
    <slot name="header"/>
  </el-header>
  <el-main class="dj-container__main">
    <slot/>
  </el-main>
  <el-footer height="auto" class="dj-container__footer">
    <slot name="footer"/>
  </el-footer>
</el-container>
</template>

<script>
export default {
  name: "dj-container"
}
</script>

<style scoped lang="scss">
.dj-container{
  &__header,&__main,&__footer{
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
&__main{
height: 100%;
}


}
</style>