Array.prototype.dj_containObjectById = function(id) {
  let result = this.dj_checkOutObjectByKeyAndValue('id',id);
  if (result.length >0){return true;}
  else {return false;}
}

Array.prototype.dj_checkOutObjectByKeyAndValue = function(key = '',value,total = false) {
  let mapper = [];
  this.filter(function(item,index){
    if (item[key] !== undefined){if (item[key].toString() === value.toString()){mapper.push(item);}}
  })
  if (total){return mapper;}
  else {
    if (mapper.length > 0){return mapper[0];}
    else {return undefined;}
  }
}

Array.prototype.dj_dictionarySortTime = function(keyName = 'create_time',desc=true) {

return this.sort(dictionarySortTime(keyName, desc))
}

function dictionarySortTime(keyName = 'create_time',desc=true) {
  return function(a, b) {
    var value1 = a[keyName];
    var value2 = b[keyName];
    if (desc) {
      // 升序
      return Date.parse(value1) - Date.parse(value2);
    } else {
      // 降序
      return Date.parse(value2) - Date.parse(value1)
    }
  }
}
