<template>
  <dj-drawer v-model="currentValue"
             :drawer-loading="loading"
             :wrapper-closable="!loading"
             @drawerCloseCallback="drawerCloseCallback">
    <div slot="header">
      <label class="dj-media-image-upload-button"
             for="uploads">选择本地图片</label>
      <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
             accept="image/png, image/jpeg, image/gif, image/jpg"
             @change="selectImgHandle($event)">
    </div>
    <div class="dj-padding-15" style="width: 100%;box-sizing: border-box;overflow: hidden">
      <div  class=" dj-media-image-upload-drawer">
        <dj-square size="100%" ratio="61.8%">
      <vue-cropper :ref="identifier"
                   :img="option.img"
                   :outputSize="option.outputSize"
                   :outputType="option.outputType"
                   :info="option.info"
                   :canScale="option.canScale"
                   :autoCrop="option.autoCrop"
                   :fixed="option.fixed"
                   :fixedNumber="option.fixedNumber"
                   :full="option.full"
                   :fixedBox="option.fixedBox"
                   :canMove="option.canMove"
                   :canMoveBox="option.canMoveBox"
                   :original="option.original"
                   :centerBox="option.centerBox"
                   :height="option.height"
                   :infoTrue="option.infoTrue"
                   :maxImgSize="option.maxImgSize"
                   :enlarge="option.enlarge"
                   :mode="option.mode"
              />

        </dj-square>
      </div>
      <div class="dj-media-image-upload-drawer-tips">
        <dj-media-icon icon="tips"/>
        <div class="dj-margin-9-left">
          图片最大支持（ 1M )，如果您要上传的图片超过（ 1M )，系统将会将自动您的图片压缩至（ 1M )。
        </div>
      </div>
    </div>
    <dj-form-footer slot="footer"
                    confirm-text="立即上传"
                    :confirm-disabled="option.img.length===0"
                    cancel-text=""
                    @dj_confirmHandle="dj_confirmHandle"/>
  </dj-drawer>
</template>

<script>
import NsDrawer from "@/@@@dj-framework/kit/dj-drawer/libs/NsDrawer";
import DjFileImageManager, {dj_data64toFile} from "@/@@@dj-framework/libs/class/file/DjFileImageManager";
import {dj_sendErrorMessage, dj_sendSuccessMessage} from "@/@@@dj-framework/libs/function/elementUi";
import DjDrawer from "@/@@@dj-framework/kit/dj-drawer/dj-drawer";
import DjMediaIcon from "@/@@@dj-framework/kit/dj-media/dj-media-icon/dj-media-icon";
import DjFormFooter from "@/@@@dj-framework/kit/dj-form/dj-form-footer";
import { VueCropper } from 'vue-cropper'
import DjSquare from "@/@@@dj-framework/kit/@dj-view/dj-square/dj-square";
import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";

export default {
  name: 'dj-media-image-upload-drawer',
  components:{DjFormFooter, DjMediaIcon, DjSquare, DjDrawer, VueCropper},
  mixins:[NsDrawer],
  props:{
    mediaRatio:{type:String,default:'80%'}

  },

  data(){
    return {
      identifier:dj_identifier(),
      present:false,
      fileObject:undefined,
      loading:false,
      option:{
        img: '',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: true,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        fixed: true,         //是否开启截图框宽高固定比例
        // autoCropWidth: "100%",  //默认生成截图框宽度
        // autoCropHeight: '100%', //默认生成截图框高度
        fixedNumber: [1, Number.dj_percentageToPoint(this.mediaRatio)], //截图框的宽高比例
        full: false,         //false按原比例裁切图片，不失真
        fixedBox: false,      //固定截图框大小，不允许改变
        canMove: true,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: false,     //上传图片按照原始比例渲染
        centerBox: true,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,    //限制图片最大宽度和高度
        enlarge: 1,          //图片根据截图框输出比例倍数
        mode: 'contain'  //图片默认渲染方式
      },
      previews: {},

    }
  },
  methods:{
    selectImgHandle(e){
      const self = this;
      let file = e.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png',
          type: "error"
        });
        return false
      }
      //转化为blob
      let reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        self.option.img = data
      }
      //转化为base64
      reader.readAsDataURL(file)
    },

    dj_confirmHandle(){
      this.loading = true;
      const self = this;
      this.$refs[this.identifier].getCropData(async (data) => {
        let imageFile = dj_data64toFile(data);
        DjFileImageManager.dj_uploadFileObjectToServe(imageFile).then(result=>{
          self.loading = false;
          dj_sendSuccessMessage('上传成功');
          self.$emit('dj_uploadSuccess',1);
          self.$emit('input',false);
        }).catch(error=>{
          self.loading = false;
          dj_sendErrorMessage('上传失败')
        })

      })

    },
    drawerCloseCallback(){
      this.fileObject = undefined;
      this.$emit('input',this.currentValue)
      this.$emit('drawerCloseCallback');
    },

    //图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs[this.identifier].changeScale(num)
    },
    //向左旋转
    rotateLeft () {
      this.$refs[this.identifier].rotateLeft()
    },
    //向右旋转
    rotateRight () {
      this.$refs[this.identifier].rotateRight()
    },

  },
  watch:{
    mediaRatio(value){
      this.option.fixedNumber = [1, Number.dj_percentageToPoint(value)];
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/@@@dj-framework/style/custom/dj-theme.scss";
.dj-media-image-upload-drawer{
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color:#f5f7fa !important;
  border:1px solid #dcdfe6 ;
  &__container{
  }
}
.dj-media-image-upload-drawer-tips{
  color: orange;
  font-size: 12px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
}

.dj-media-image-upload-button {
  outline: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  -webkit-transition: .1s;
  transition: .1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: $dj-type-primary !important;
  border-color: #409EFF;
  margin-right: 10px;
}
</style>
