import {dj_identifier} from "@/@@@dj-framework/libs/category/string/@index";

class DjFileManger {
    constructor() {
        //文件名
        this.fileName = '';
        //后缀
        this.sufix = '';

        this.accept = '*';

        this.multiple = false;
    }


    dj_toServe(file,type){

    }


    static dj_localSingleFile(){
        let manager = new DjFileManger();
        return new Promise((resolve, reject) => {
            dj_selectLocalFile(manager.accept).then(result=>{
                resolve(result);
            }).catch(error=>{
                reject(error);
            })
        })
    }





}
export default DjFileManger;

export function dj_fileToTempUrl(fileObject){
    let getUrl = null
    if (window.createObjectURL !== undefined) {
        getUrl = window.createObjectURL(fileObject)
    } else if (window.URL !== undefined) {
        getUrl = window.URL.createObjectURL(fileObject)
    } else if (window.webkitURL !== undefined) {
        getUrl = window.webkitURL.createObjectURL(fileObject)
    }
    return getUrl;
}
export function dj_blobToFile(blobUrl,type = 'video/mp4'){
    let name = type.dj_toArray('/');
    let file = new window.File([blobUrl], dj_identifier(16)+'.'+name.dj_lastObject(), {type: type, lastModified: Date.now()})
    return file;
}
export function dj_tempUrlToFile(tempUrl,type = 'video/mp4'){
    let suffix = type.dj_toArray('/');
    return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", tempUrl);
        xhr.setRequestHeader('Accept', type);
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
            blob = xhr.response;
            let file= new File([blob], dj_identifier(16)+'.'+suffix.dj_lastObject(), { type: type });
            // 返回结果
            file['tempUrl'] = tempUrl;
            resolve(file);
        };
        xhr.onerror = (e) => {
            reject(e)
        };
        // 发送
        xhr.send();
    });
}
export function dj_selectLocalFile(accept='*',multiple=false,base64=false) {
    return new Promise((resolve, reject) => {
        let fileInputElement = document.createElement("input");
        fileInputElement.setAttribute("type", "file");
        fileInputElement.setAttribute("accept", accept);
        if (multiple){fileInputElement.setAttribute("multiple",multiple);}
        fileInputElement.style.display = "none";
        fileInputElement.addEventListener("change", () => {
            let files = fileInputElement.files;
            let mapper = [];
            let requests = [];
            for (let index = 0; index< files.length; index++){
                let file = files[index];
                requests.push(dj_fileToBase64(file))
                file['blob'] = dj_fileToTempUrl(file)
                mapper.push(file);
            }
            Promise.all(requests).then(result=>{
                let target = [];
                for (let index = 0; index < mapper.length;index++){let file = mapper[index];target.push(file);}
                fileInputElement.removeAttribute("multiple");
                fileInputElement.removeAttribute("accept");
                document.body.removeChild(fileInputElement);
                if (target && target.length) {
                    if (multiple){resolve(target);}
                    else {resolve(target[0]);}
                }
                else {reject();}
            })
        });
        fileInputElement.value = "";
        document.body.appendChild(fileInputElement);
        fileInputElement.click();
    });

}
export function dj_fileToBase64(file) {
    return new Promise(resolve => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = function () {
            resolve(this.result)
        }
    })
}
export function dj_data64toFile(base64Data,file_name = dj_identifier(16)) {
    let arr = base64Data.split(","),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);
    while (l--) {u8Arr[l] = bstr.charCodeAt(l);}
    let newBlob = new Blob([u8Arr], {type: fileType});
    newBlob.lastModifiedDate = new Date();
    newBlob.name = file_name+'.'+fileType.dj_fileTypeSuffix();
    newBlob['base64'] = base64Data;
    newBlob['active'] = 0;
    return newBlob;
}
