export default {
    props:{
        dataSource:{type:Array,default:function () {return []}},
        paginate:{type:Object,default:function () {return {page: 1, pageSize: 20, total: 0 }}},
        loading:{type:Boolean,default:false},
        columns:{type:[String,Number],default:4},
        space:{type:[String],default:'1px'},
        border:{type:Boolean,default:false},
        pagination:{type:Boolean,default:true},
        batchDelete:{type:Boolean,default:true},
        total:{type:Number,default:0},

    },
}