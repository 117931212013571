<template>
  <div class="dj-border dj-pagination">
    <div v-if="batchDelete"
         class="dj-pagination__check">
      <el-checkbox v-model="currentValue"
      :indeterminate="indeterminate"
                   :disabled="loading"
      @change="dj_checkAllChange">
        全选
      </el-checkbox>
      <dj-button-delete :disabled="selectIds.length === 0 || loading"
                        @click="dj_batchDeleteHandle">
        <el-button :disabled="selectIds.length === 0" size="mini" type="danger">批量删除</el-button>
      </dj-button-delete>
    </div>
    <div class="dj-pagination__info">
      <el-pagination v-if="pagination"
                     :current-page="currentPage"
                     :disabled="loading"
                     :page-size="pageSize"
                     background
                     small="small"
                     layout="prev, pager, next"
                     :total="total"
                     @current-change="dj_pageChangeHandle"/>
    </div>
  </div>
</template>

<script>
import DjButtonDelete from "@/@@@dj-framework/kit/dj-button/dj-button-delete";
export default {
  name: "dj-pagination",
  components: {DjButtonDelete},
  props:{
    pagination:{type:Boolean,default:true},
    total:{type:Number,default:0},
    batchDelete:{type:Boolean,default:true},
    page:{type:Number,default:1},
    pageSize:{type:Number,default:1},
    selection:{type:Boolean,default:true},
    loading:{type:Boolean,default:false},
    value:{type:Boolean,default:false},
    indeterminate:{type:Boolean,default:false},
    selectIds:{type:Array,default:function () {return [];}},
  },
  data(){
    return {
      currentValue:this.value,
      currentPage:this.page,
      currentPage1:10

    }
  },
  methods:{
    dj_pageChangeHandle(page){
      this.$emit('dj_pageChangeHandle',page);
    },
    dj_batchDeleteHandle(){
      this.$emit('dj_batchDeleteHandle');
    },
    dj_checkAllChange(value){
      this.$emit('dj_paginationCheckAllChange',value);
    }

  },
  watch:{
    value(val){
      this.currentValue = val
    },
    'page'(value){
      this.currentPage = value;
    }
  }
}
</script>

<style scoped lang="scss">
.dj-pagination{
  display: flex;
  align-items: center;
  padding: 12px 0 12px 12px;
  grid-gap: 9px;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: space-between;
  border-top: none;
  &__check{
    padding-left: 4px;
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }
  &__info{
    padding-left: 6px;
    box-sizing: border-box;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
